







import Vue from 'vue';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
// import { TypeVisa, TypeSetMeal } from './type';
// import { visaTypeOption, bizTypeOption } from './helper';
import { randomServiceList } from './service';
import AirTicketSearch from '~/components/AirTicket/Search.vue';
import moment from 'moment';

export default Vue.extend<any, any, any>({
  components: {
    AirTicketSearch,
  },
  filters: {
    getContentText(val: string): string {
      var reg = /<[^>]+>/gi;
      // 过滤html标签
      let str = val ? val.replace(reg, '') : '';
      return str;
    },
    firstLink(val: string): string {
      const [areaId] = val.split('-').slice(1, 1);

      if (areaId === '0') {
        return '/visa/';
      } else {
        return val;
      }
    },
  },
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   console.log(122, path);
  //   if (path === '/visa' || path === '/visa/') {
  //     return true;
  //   }
  //   // const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
  //   // return _validate;
  // },
  watchQuery(newQuery: any, oldQuery: any) {
    // Only execute component methods if the old query string contained `bar`
    // and the new query string contains `foo`
    console.log('watchQuery-47:', newQuery);
    return newQuery.foo && oldQuery.bar;
  },
  async asyncData(ctx) {
    // console.log(130, ctx.query);
    // const { page = '1', areaId = '' } = ctx.query;
    const {
      // visaAreaId = 0,
      // areaId = 0,
      // type = 0,
      // bizType = 0,
      page = '1',
    } = ctx.params;
    // console.log(322, visaAreaId, areaId, type, bizType);
    const pageNum = isNaN(+page) ? 1 : +page;
    const pageSize = 12;

    const listParams = {
      // visaPlace: visaAreaId != 0 ? visaAreaId : null,
      // areaId: areaId != 0 ? areaId : null,
      // type: type != 0 ? type : null,
      // bizType: bizType != 0 ? bizType : null,
      // name: '',
      pageNum,
      pageSize,
    };

    return {};
  },
  data(): Record<string, any> {
    return {
      randomServiceList,
      tags: ['不支持多选'],
      pageNum: 1,
      listQuery: {
        departCity: '杭州',
        depCityCode: 'HGH',
        depDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        arriveCity: '成都',
        arrCityCode: 'CTU',
        arrDate: '',
        cabinClass: 'ALL_CABIN',
        tripType: 1,
        needMultiClassPrice: true,
        transferLeaveDate: '',
      },
    };
  },
  mounted() {
    // console.log(296, this.$route.params);
    // let {
    //   visaAreaId = '',
    //   areaId = '',
    //   type = '',
    //   bizType = '',
    // } = this.$route.params;
    // this.listQuery.visaAreaId = visaAreaId;
    // this.listQuery.areaId = areaId;
    // this.listQuery.type = type;
    // this.listQuery.bizType = bizType;
    // 默认为今天的日期
    // this.listQuery.depDate = moment().format('YYYY-MM-DD');
    // this.listQuery.depDate = moment().add(1, 'days').format('YYYY-MM-DD');
  },
  methods: {
    // 获取列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const ticketRes: any = await this.$axios.post('/api/visaInfoApi/search', {
        status: this.ETabStatus[this.activeType],
        pageNum,
        pageSize,
      });

      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticketRes.data.records.map((item: any) => {
          item['visaMainPic'] = item.visaPic ? item.visaPic.split(',')[0] : '';
        });
        this.ticket = ticketRes.data;
      }
    },
    handleVisaArea(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.visaAreaId = id;

      this.$router.push({
        path: `/visa-${id}-${areaId}-${type}-${bizType}-1/`,
        query: this.$route.query,
      });
    },
    handleVisaType(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.type = id;

      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${id}-${bizType}-1/`,
        query: this.$route.query,
      });
    },
    handleVisaBizType(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.bizType = id;

      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${type}-${id}-1/`,
        query: this.$route.query,
      });
    },
    handleFilterArea(id: string) {
      const {
        visaAreaId = 0,
        areaId = 0,
        type = 0,
        bizType = 0,
      } = this.$route.params;
      this.listQuery.areaId = id;

      this.$router.push({
        path: `/visa-${visaAreaId}-${id}-${type}-${bizType}-1/`,
        query: this.$route.query,
      });
    },

    // 翻页
    changePager(page: number) {
      const { visaAreaId = 0, areaId = 0, type = 0, bizType = 0 } = this;
      console.log(504, '翻页', visaAreaId);
      // ${visaAreaId}-${areaId}-${type}-${bizType}
      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${type}-${bizType}-${page}/`,
        query: this.$route.query,
      });
    },
    getList() {},
    handleClick(item: any) {
      let path = `/visa/${item.id}.html`;
      if (item.id === '0') {
        path = '/visa/';
      }
      this.$router.push({
        path,
      });
    },
  },
  head() {
    const { pageNum } = this.$data;
    return {
      title: `签证${
        pageNum > 1 ? `_第${pageNum}页` : ''
      }-办理-材料-价格-时间-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `聚展网`,
        },
      ],
    };
  },
});
